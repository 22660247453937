<template>
  <div>
    <v-overlay
      :model-value="isOpen"
      scroll-strategy="reposition"
    />
    <div
      :ref="'alert_' + alert"
      style="position: relative"
      :style="isOpen ? 'z-index: 3000;' : ''"
    >
      <slot
        name="activator"
        :toggle="toggle"
      >
        <v-btn
          :id="alert"
          class="no-focus white-background"
          color="primary"
          size="x-small"
          :variant="!isOpen ? 'outlined' : 'flat'"
          :elevation="isOpen ? 8 : 0"
          icon
          selected-class="no-active"
        >
          <v-icon size="large"> mdi-help </v-icon>
        </v-btn>
      </slot>
    </div>
    <v-menu
      v-model="isOpen"
      :activator="'#' + alert"
      :persistent="!!isTour"
      :close-on-content-click="!isTour"
      transition="slide-y-transition"
      :offset="nudgeBottom"
    >
      <v-alert
        class="pa-4"
        elevation="8"
        density="compact"
        :width="$vuetify.display.mobile ? 300 : 600"
        data-cy="alert"
      >
        <slot />
        <div v-if="isTour">
          <v-divider class="my-4" />

          <v-row
            align="center"
            dense
          >
            <v-col class="shrink">
              <v-btn
                v-if="currentIndex > 0 && onPage.length > 1"
                color="primary"
                variant="outlined"
                @click="back"
              >
                Zurück
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col class="shrink">
              <v-btn
                v-if="currentIndex < onPage.length - 1"
                color="primary"
                variant="outlined"
                data-cy="skip"
                @click="skip"
              >
                Überspringen
              </v-btn>
            </v-col>
            <v-col class="shrink">
              <v-btn
                color="primary"
                variant="elevated"
                rounded
                class="px-10"
                data-cy="forward"
                @click="forward"
              >
                {{ currentIndex === onPage.length - 1 ? 'Verstanden' : 'Weiter' }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-alert>
    </v-menu>
  </div>
</template>

<script>
import { mapWritableState, mapActions } from 'pinia'
import { useAlertStore } from '~/store/alerts'
export default {
  props: {
    alert: {
      type: String,
      required: true,
    },
    nudgeBottom: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      x: 0,
      y: 0,
    }
  },
  computed: {
    ...mapWritableState(useAlertStore, ['isTour', 'onPage', 'open']),
    isOpen: {
      get() {
        return this.open === this.alert
      },
      set(newValue) {
        if (newValue) {
          this.open = this.alert
        } else {
          this.open = null
        }
      },
    },
    currentIndex() {
      return this.onPage.indexOf(this.alert)
    },
  },
  watch: {
    isOpen(value) {
      if (value) {
        this.scrollToElement()
        this.setPosition()
      }
    },
  },
  created() {
    this.add(this.alert)
  },
  mounted() {
    this.setPosition()
    addEventListener('scroll', () => {
      if (this.isOpen) {
        this.setPosition()
      }
    })
    /*
    const seenAlerts = this.getCookie()
    setTimeout(() => {
      if (
        this.alert ===
        this.onPage.filter((alertId) => !seenAlerts || !seenAlerts.includes(alertId))[0]
      ) {
        this.isTour = true
        this.isOpen = true
      }
    }, 500)
    */
  },
  methods: {
    ...mapActions(useAlertStore, ['add']),
    toggle() {
      this.isOpen = true
    },
    scrollToElement() {
      const rect = this.$refs['alert_' + this.alert].getBoundingClientRect()
      const isInViewport =
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      if (!isInViewport) {
        if (this.$refs['alert_' + this.alert]) {
          if (this.$refs['alert_' + this.alert].$el) {
            this.$refs['alert_' + this.alert].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
            })
          } else {
            this.$refs['alert_' + this.alert].scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
            })
          }
        }
      }
    },
    setPosition() {
      this.x = this.$refs['alert_' + this.alert].getBoundingClientRect().left
      this.y = this.$refs['alert_' + this.alert].getBoundingClientRect().top
    },
    back() {
      const previous = this.onPage[this.currentIndex - 1]
      this.open(previous)
    },
    forward() {
      this.setCookie([this.alert])
      if (this.currentIndex === this.onPage.length - 1) {
        this.open = null
        this.isTour = false
      } else {
        const next = this.onPage[this.currentIndex + 1]
        this.open = next
      }
    },
    skip() {
      this.setCookie(this.onPage)
      this.open = null
      this.isTour = false
    },
    setCookie(alertIds) {
      let cookie = this.getCookie()
      if (cookie) {
        alertIds.forEach((alertId) => {
          if (!cookie.includes(alertId)) {
            cookie.push(alertId)
          }
        })
      } else {
        cookie = alertIds
      }
      document.cookie =
        'seenAlerts=' + JSON.stringify(cookie) + ';path=/;expires=Fri, 31 Dec 9999 23:59:59 GMT'
    },
    getCookie() {
      const cookie = document.cookie
        .split('; ')
        .find((row) => row.startsWith('seenAlerts='))
        ?.split('=')[1]
      return cookie ? JSON.parse(cookie) : null
    },
  },
}
</script>
<style lang="scss" scoped>
.no-focus:focus::before {
  opacity: 0 !important;
}
.white-background {
  background-color: #ffffff;
}
</style>
